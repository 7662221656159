@use 'mixins' as *;

// for now, 1 place to control all mobile overrides, since we do not normally support this.
// only used for invite-response, but should override global classes.

@include media-tiny {
  button.button,
  a.button {
    padding: 0 5px;
    text-align: left;
  }

  .filter-label {
    white-space: normal;
    max-width: 90%;
  }

  .select-dropdown .button {
    padding: 0;

    .control-value:not(.filter-label) {
      padding: 0 15px;
    }

    .muted {
      padding: 0 15px;
    }
  }

  .buttons-container {
    font-size: 0.8rem;
  }
}
