.badge {
  vertical-align: middle;
  font-size: calc(0.8 * var(--font-size-normal));
  border-radius: var(--legacy-br);

  text-align: center;
  display: inline-block;
  min-width: var(--legacy-badge-size);
  width: var(--legacy-badge-size);
  height: var(--legacy-badge-size);
  line-height: 1.7rem;

  padding: 0;

  // default color scheme
  color: var(--col-text-secondary);
  background-color: transparent;
  border: 1px solid var(--legacy-col-lines);

  &.badge-circle {
    border-radius: 50%;
  }

  &.progress,
  &.orange,
  &.positive,
  &.blue,
  &.sky-blue,
  &.negative,
  &.neutral {
    color: white !important;
    border: none !important;
  }

  &.progress {
    background-color: var(--legacy-col-progress);
  }

  &.orange {
    background-color: var(--legacy-orange);
  }

  &.blue {
    background-color: var(--legacy-blue);
  }

  &.sky-blue {
    background-color: var(--legacy-sky-blue);
  }

  &.positive {
    background-color: var(--legacy-col-positive);
  }

  &.negative {
    background-color: var(--legacy-col-negative) !important;
  }

  &.neutral {
    background-color: var(--legacy-gray);
  }

  &.not-shared {
    background-color: var(--legacy-light-gray);
  }

  &.white {
    background-color: white !important;
    border: none;
    color: inherit;
    &.positive {
      color: var(--legacy-col-positive) !important;
    }
    &.negative {
      color: var(--legacy-col-negative) !important;
    }
  }

  &.muted {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.badge.clickable,
canmed-score-label.clickable > .badge,
canmed-count-label.clickable > .badge {
  cursor: pointer;
  transition: box-shadow 0.5s;

  &:hover {
    box-shadow: 0 0 0 5px var(--col-accent);
  }
}

.badge.big,
.big > .badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 1em;
  font-weight: normal;
  width: calc(1.5 * var(--legacy-badge-size));
  height: calc(1.5 * var(--legacy-badge-size));
  line-height: calc(1.5 * var(--legacy-badge-size));
}

.badge.medium,
.medium > .badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 1em;
  font-weight: normal;
  width: calc((1 + (1 / 3)) * var(--legacy-badge-size));
  min-width: calc((1 + (1 / 3)) * var(--legacy-badge-size));
  height: calc((1 + (1 / 3)) * var(--legacy-badge-size));
  line-height: calc((1 + (1 / 3)) * var(--legacy-badge-size));
}

.badge.small,
.small > .badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 0.75em;
  font-weight: normal;
  min-width: calc(0.5 * var(--legacy-badge-size));
  width: calc(0.5 * var(--legacy-badge-size));
  height: calc(0.5 * var(--legacy-badge-size));
  line-height: calc(0.5 * var(--legacy-badge-size));
}

.badge.huge,
.huge > .badge {
  font-size: 1.5em;
  font-weight: normal;
  width: calc(2 * var(--legacy-badge-size));
  height: calc(2 * var(--legacy-badge-size));
  line-height: calc(2 * var(--legacy-badge-size));
}
