/*Use for web apps:*/

:root {
  --legacy-default-spacing: 40px;
  --legacy-default-spacing-s: calc(0.5 * var(--legacy-default-spacing));
  --legacy-default-spacing-xs: calc(0.25 * var(--legacy-default-spacing));
  --legacy-default-padding: 30px;
  --legacy-default-padding-s: calc(0.5 * var(--legacy-default-padding));
  --legacy-default-padding-xs: calc(0.25 * var(--legacy-default-padding));
  --legacy-sidebar-padding: 20px;
  --legacy-default-element-spacing: 20px;
  --legacy-default-element-spacing-s: calc(0.5 * var(--legacy-default-element-spacing));
  --legacy-default-element-spacing-xs: calc(0.25 * var(--legacy-default-element-spacing));

  --legacy-navbar-height: 54px;
  --legacy-navbar-width: 64px;
  --legacy-navbar-width-expanded: 310px;
  --legacy-navbar-secondary-width: 310px;
  --legacy-navbar-item-height: var(--legacy-navbar-height);
  --legacy-item-height: 60px;
  --legacy-button-height: 40px;
  --legacy-button-height-small: 30px;
  --legacy-label-height-small: 24px;
  --legacy-input-height: 46px;
  --legacy-badge-size: 30px;

  --legacy-br: 5px;

  /*padding inside smaller elements such as buttons and tiles*/
  --legacy-internal-padding: 10px;
  --legacy-button-spacing: 10px;

  --legacy-mobile-breakpoint: 600px;

  /*Use for Ionic apps:*/
  --legacy-app-border-radius: 6px;
  --legacy-app-border-radius-large: 25px;

  /*main content padding; also used in other places, e.g. padding of info-box*/
  --legacy-app-padding: 16px;

  /*vertical spacing between elements that are part of the same list / group of elements*/
  --legacy-app-element-spacing: 8px;

  /*spacing below headers in sections*/
  --legacy-app-header-spacing: 16px;

  /*spacing between sections that are distinctly separate functionalities*/
  --legacy-app-section-spacing: 32px;

  --legacy-app-button-height: 46px;

  --legacy-app-input-height: 46px;
  --legacy-app-input-padding: 12px;

  --legacy-table-cell-padding: 15px;

  --legacy-container-max-width-desktop: 1280px;
  --legacy-modal-side-bar-max-width: 560px;

  --br: 5px;

  --spacing-xs: 4px;
  --spacing-sm: 6px;
  --spacing-md: 8px;
  --spacing-lg: 12px;
  --spacing-xl: 16px;
  --spacing-xxl: 24px;
  --size-3xs: 4px;
  --size-xxs: 8px;
  --size-xs: 16px;
  --size-sm: 24px;
  --size-md: 32px;
  --size-lg: 48px;
  --size-xl: 64px;
  --width-3xs: 160px;
  --width-xxs: 240px;
  --width-xs: 320px;
  --width-sm: 480px;
  --width-md: 640px;
  --width-lg: 1024px;
  --width-xl: 1280px;
  --container-max-width-desktop: var(--width-xl);
  --container-padding-desktop: var(--spacing-xxl);
  --container-padding-mobile: var(--spacing-xl);

  --login-sidebar-width: var(--width-md);
  --login-content-width: var(--width-sm);
  --sidebar-width-collapsed: 68px;
  --sidebar-width-expanded: var(--width-xxs);
  --sidebar-padding: var(--spacing-xl);
  --drawer-width: var(--width-xs);
  --section-spacing-between: var(--spacing-xxl);
  --section-padding: var(--spacing-xl);
  --tab-content-spacing: var(--spacing-xl);
  --form-field-spacing: var(--spacing-xl);
  --form-horizontal-left-width: var(--width-3xs);
  --form-header-spacing: var(--spacing-lg);
  --dropout-feature-width: var(--width-sm);
  --dropout-feature-padding: var(--spacing-xl);
  --tooltip-max-width: var(--width-xs);
  --tooltip-distance: var(--spacing-sm);
  --tooltip-arrow-offset: var(--spacing-xl);
  --modal-width-small: var(--width-sm);
  --modal-width-medium: var(--width-md);
  --modal-width-large: var(--width-lg);
  --modal-width-minimized: var(--width-xs);
  --modal-padding: var(--spacing-xxl);
  --modal-sidebar-max-width: var(--width-md);
  --modal-auto-collapse-width: var(--width-lg);
  --dropdown-width: var(--width-xxs);
  --dropdown-width-second-level: var(--width-xs);
  --text-header-spacing: var(--spacing-md);
  --text-label-in-item-vertical-spacing: var(--spacing-xs);
  --text-paragraph-spacing: var(--spacing-md);
  --text-max-paragraph-width: var(--width-md);
  --tile-padding: var(--spacing-lg);
  --tile-spacing: var(--spacing-lg);
  --tile-container-collapse-width: var(--width-sm);
  --tile-min-width: var(--width-3xs);
  --item-height-md: var(--size-md);
  --item-height-sm: var(--size-sm);
  --item-height-xs: var(--size-xs);
  --item-padding-md: var(--spacing-md);
  --item-padding-sm: var(--spacing-sm);
  --item-padding-xs: var(--spacing-xs);
  --item-internal-spacing-md: var(--spacing-md);
  --item-internal-spacing-sm: var(--spacing-sm);
  --item-spacing-between-md: var(--spacing-md);
  --list-item-spacing: var(--spacing-xs);
  --table-spacing: var(--spacing-xs);
  --label-height-sm: var(--size-sm);
  --badge-size-xxs: var(--size-xxs);
  --badge-size-xs: var(--size-xs);
  --badge-size-sm: var(--size-sm);
  --badge-size-md: var(--size-md);
  --badge-size-lg: var(--size-lg);
  --border-radius-md: 6px;
  --border-radius-sm: 4px;
  --input-min-width: var(--width-3xs);
  --input-textarea-height: var(--size-xl);
  --input-checkbox-size: var(--size-xs);
  --input-toggle-width: var(--size-md);
  --input-toggle-inner-size: 14px;
  --progress-bar-height: var(--size-xxs);
  --progress-bar-height-sm: var(--size-3xs);
  --dashboard-max-tile-width: var(--width-xxs);
}

/*all 'old' restyle we prefix with not-*/
.not--restyle_ft--border-radius {
  --br: 2px;
}
