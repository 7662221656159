@mixin basic-form-field-style() {
  padding: 0 var(--legacy-internal-padding);
  display: flex;
  align-items: center;

  min-height: var(--legacy-input-height);
  height: var(--legacy-input-height); //set height for IE vertical alignment issue

  border: 1px solid var(--legacy-col-lines);
  border-radius: var(--legacy-br);

  color: var(--col-text-primary);
  font-family: var(--font-default);
  font-size: inherit;

  background-color: white;

  transition:
    border-color 0.5s,
    background-color 0.5s;

  &.disabled,
  &[disabled] {
    color: var(--col-text-muted);
    background: var(--col-inactive);
    cursor: default;
  }
}

input[type='text'],
input[type='email'],
input[type='number'],
input[type='date'],
input[type='password'],
textarea,
.datepicker-value {
  @include basic-form-field-style;

  & {
    width: 100%;

    &.big {
      font-size: larger;
    }

    &.button-height {
      height: var(--legacy-button-height);
      min-height: var(--legacy-button-height);
    }

    &.hide-border:not(:focus):not(:hover):not(.ng-invalid) {
      border-color: transparent;
    }

    // only show border when in focus
    &.input-camouflaged {
      border-color: transparent;
      transition: border-color 0.3s;

      &:hover:not(:focus) {
        border-color: var(--legacy-col-lines);
      }
    }

    &:focus {
      outline: none;
      border-color: var(--legacy-col-active);
      color: var(--legacy-col-active);
    }
  }
}

// only use this experimental style in rcp-form-row (for now) because of unknown consequences
// in form generator or other places
// must be declared in global styles because of ng-content
rcp-form-row .form-row-content {
  input,
  textarea {
    &.ng-invalid.ng-touched {
      border-color: var(--legacy-col-negative);
    }
  }
}

textarea {
  font-size: inherit;
  padding-top: 12px;
  min-height: 100px;

  &.single-line {
    min-height: var(--legacy-input-height);
  }
}

button.dropdown-opener-button {
  @include basic-form-field-style;

  & {
    width: 100%;
    height: auto;
    text-align: left;
    cursor: pointer;

    &:hover:not(.disabled) {
      border-color: var(--legacy-col-active);
      color: var(--legacy-col-active);
    }

    .dropdown-value-container {
      flex-grow: 1;

      .filter-labels {
        display: flex;
        flex-wrap: wrap;

        .filter-label {
          margin: 3px;
        }
      }
    }
  }
}

// placeholder text
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--col-text-muted);
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--col-text-muted);
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--col-text-muted);
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--col-text-muted);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--col-text-muted);
}

.filters {
  margin-top: -10px;
  margin-bottom: 10px;
  width: 100%;

  .search {
    position: relative;
    margin-bottom: var(--legacy-default-element-spacing);

    fa-icon {
      position: absolute;
      color: var(--col-accent);
      top: 50%;
      transform: translate3d(0, -50%, 0);
      left: 15px;
    }

    input {
      padding-left: 40px;
    }
  }
}

// display a border for an inset form
.form-border {
  border-radius: 10px;
  border: 1px solid var(--legacy-col-lines);

  section:last-child {
    border-bottom: none;
  }
}

.form-input-error {
  background-color: var(--col-negative-bg);
  color: var(--col-negative-dark);
  padding: 10px;
  font-size: smaller;
  margin-top: 10px;
  border-radius: var(--legacy-br);
  display: flex;
  align-items: center;
}

section p.form-description {
  white-space: pre-line;
  margin-top: -10px;
  font-style: italic;
}

section ul.form-description-list {
  white-space: pre-line;
  margin-top: -10px;
  font-style: italic;
}

// optional container for input
.input {
  position: relative;
  .right.floating.button {
    position: absolute;
    right: 3px;
    top: 8px;
  }

  &.detailed {
    $detail-width: 46px;

    &.right {
      input {
        padding-right: $detail-width + 16px;
      }
      .detail,
      > fa-icon {
        right: 0;
        border-left: 1px solid var(--legacy-col-lines);
      }
    }
    &:not(.right) {
      input {
        padding-left: $detail-width + 16px;
      }
      .detail,
      > fa-icon {
        left: 0;
        border-right: 1px solid var(--legacy-col-lines);
      }
    }

    .detail,
    > fa-icon {
      pointer-events: none; //so that on click the input gets focus
      position: absolute;
      top: 1px;
      bottom: 1px;
      background: rgba(black, 0.03);
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 15px;
    }
  }
}
